<template>
  <div class="artist-gallery" @wheel="handleWheelScroll">
    <div class="artist-gallery__container-wrap">
      <div ref="scrollContainer" class="artist-gallery__thumbnail-wrap">
        <div
          v-for="(media, index) in artistImages"
          :key="index"
          class="artist-gallery__image-item"
        >
          <div class="artist-gallery__img-wrap" @click="openLightbox(index)">
            <!-- 이미지 렌더링 -->
            <img 
              v-if="media.mediaType === 'image'" 
              v-lazy="media.src" 
              :alt="media.description" 
            />
            <!-- Vimeo 영상 렌더링 (iframe 태그 그대로 삽입) -->
            <div v-else-if="media.mediaType === 'externalIframe'" v-html="media.iframeContent"></div>
          </div>
        </div>
      </div>
      <div class="artist-gallery__title-wrap">
        <h5 class="artist-gallery__title">
          {{ artistImages.length > 0 ? artistImages[0].description : 'No Title & Media' }}
        </h5>
      </div>
    </div>
    <easy-lightbox 
      :visible="visible" 
      :imgs="lightboxImages" 
      :index="lightboxIndex" 
      @hide="handleHide" 
      :move-disabled="true"   
      :swipeTolerance="50"
      :loop="true"
    ></easy-lightbox>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import artistWorkDetail from '@/data/artistWorkDetail';

export default {
  props: {
    artistId: String,
    workId: String,
  },
  setup(props) {
    const visible = ref(false);
    const lightboxIndex = ref(0);
    const scrollContainer = ref(null);

    // Artist Media 데이터를 가져옴
    const artistImages = computed(() => {
      return artistWorkDetail[props.artistId]?.[props.workId] || [];
    });

    const lightboxImages = computed(() => {
      return artistImages.value
        .filter((media) => media.mediaType === 'image')
        .map((image) => image.src);
    });

    const openLightbox = (index) => {
      if (artistImages.value[index]?.mediaType === 'image') {
        lightboxIndex.value = index;
        visible.value = true;
      }
    };

    const handleHide = () => {
      visible.value = false;
    };

    // 스크롤 이벤트 핸들링 (ref를 활용)
    const handleWheelScroll = (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (scrollContainer.value) {
        // deltaY와 deltaX를 함께 사용하여 상하, 좌우 스크롤 모두 처리
        scrollContainer.value.scrollLeft += event.deltaY + event.deltaX;
      }
    };

    // 스크롤 위치 초기화 함수
    const resetScroll = () => {
      if (scrollContainer.value) {
        scrollContainer.value.scrollLeft = 0;
      }
    };

    // 컴포넌트가 마운트될 때 스크롤 위치 초기화
    onMounted(() => {
      resetScroll();
    });

    // artistId 또는 workId 변경 시 스크롤 위치 초기화
    watch(() => [props.artistId, props.workId], () => {
      resetScroll();
    });

    return {
      visible,
      lightboxIndex,
      artistImages,
      lightboxImages,
      openLightbox,
      handleHide,
      handleWheelScroll,
      scrollContainer,
    };
  },
};
</script>

<style scoped>
.artist-gallery {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.artist-gallery__thumbnail-wrap {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden; /* 상하 스크롤 숨김 */
  padding-bottom: 10px;
  white-space: nowrap; /* 자식 요소들이 한 줄로 표시 */
}

.artist-gallery__image-item {
  display: inline-block;
  margin-right: 10px;
}

.artist-gallery__img-wrap img {
  max-height: 700px;
  object-fit: contain;
  cursor: pointer;
}

.artist-gallery__img-wrap iframe {
  width: 100%;
  height: 100%;
  border: none;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .artist-gallery__img-wrap img {
    max-height: 500px;
    object-fit: contain;
  }
}
</style>